<template>
  <div class="wrapper">
    <div
      class="tw-relative"
      :class="{
                'tw-rounded tw-border-gray-50 tw-border-2': !noBorder,
                'tw-p-6 tw-pt-8': !dense,
                'tw-p-0': dense
            }"
    >
      <span
        v-if="subTitle"
        class="tw-absolute tw-bg-gray-50 tw-rounded tw-px-2 tw-text-xs tw-leading-relaxed subtitle tw-text-gray-800"
      >{{ subTitle }}</span>

      <slot></slot>
    </div>
    <div v-show="bottomLine" style="height: 4rem"></div>
  </div>
</template>

<script>
export default {
  props: {
    moveUpDisabled: {
      type: Boolean,
      default: false
    },
    moveDownDisabled: {
      type: Boolean,
      default: false
    },
    bottomLine: {
      type: Boolean,
      default: false
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    subTitle: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.subtitle {
  top: -0.7rem;
}
</style>
