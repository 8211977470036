<template>
  <div class="tw-mx-auto tw-w-full tw-max-w-xl lg:tw-max-w-screen-xl">
    <div class="tw-flex tw-flex-row xl:tw-space-x-8">
      <div class="tw-hidden xl:tw-block xl:tw-w-44 tw-flex-shrink-0">
        <div v-sticky="{ zIndex: 1, stickyTop: 110, disabled: false }">
          <div class="tw-flex tw-flex-col tw-space-y-4 tw-text-right">
            <a
              @click="$vuetify.goTo('#Odkaz', { offset: 145 })"
              class="tw-text-base tw-text-gray-800 tw-no-underline hover:tw-text-gray-500 tw-transition-colors tw-duration-200"
            >
              {{ $t('profile.link') }}
            </a>
            <a
              @click="$vuetify.goTo('#Jazyk', { offset: 100 })"
              class="tw-text-base tw-text-gray-800 tw-no-underline hover:tw-text-gray-500 tw-transition-colors tw-duration-200"
            >
              {{ $t('profile.language') }}
            </a>
            <a
              @click="$vuetify.goTo('#Súkromie', { offset: 100 })"
              class="tw-text-base tw-text-gray-800 tw-no-underline hover:tw-text-gray-500 tw-transition-colors tw-duration-200"
            >
              {{ $t('profile.privacy') }}
            </a>
          </div>
        </div>
      </div>

      <div class="tw-w-full">
        <div class="tw-flex tw-flex-col lg:tw-flex-row tw-space-y-8 lg:tw-space-y-0 lg:tw-space-x-8">
          <section class="tw-w-full">
            <div class="tw-bg-white tw-rounded-2xl tw-p-4 lg:tw-p-8">
              <form @submit.prevent="submit">
                <h3 id="Odkaz" class="tw-heading-3">{{ $t('profile.link') }}</h3>
                <p class="tw-mt-2">{{ $t('profile.descriptions.resume_link') }}</p>

                <k-input
                  :value="cvUrl"
                  type="text"
                  id="cvURL"
                  ref="cvURL"
                  tabindex="-1"
                  class="cv-url-input tw-mt-6"
                  disabled
                />

                <k-button
                  @click="copyToClipboard()"
                  :success="successfullyCopied"
                  type="button"
                  class="tw-mt-4"
                >
                  {{ $t('global.actions.copy') }}
                </k-button>

                <h3 id="Jazyk" class="tw-mt-12 tw-heading-3">{{ $t('profile.language') }}</h3>
                <p class="tw-mt-2">{{ $t('profile.descriptions.resume_language') }}</p>

                <k-select
                  v-model="form.language"
                  :items="languages"
                  :placeholder="$t('profile.language')"
                  class="tw-mt-6"
                />

                <h3 id="Súkromie" class="tw-mt-12 tw-heading-3">{{ $t('profile.privacy') }}</h3>
                <p class="tw-mt-2">{{ $t('profile.descriptions.resume_availability') }}</p>

                <v-radio-group
                  v-model="form.audience"
                  hide-details
                  class="tw-p-0 tw-mt-6"
                  @change="handleAudienceChange"
                >
                  <k-radio :label="$t('profile.public')" value="PUBLIC" />
                  <k-radio :label="$t('profile.private')" value="PRIVATE" />
                </v-radio-group>

                <div
                  v-if="!hasUserGdprAccepted"
                  class="tw-mt-6 tw-border-0 tw-border-t tw-border-solid tw-border-gray-300"
                >
                  <k-gdpr
                    class="tw-mt-6"
                    v-model="gdpr"
                    :showNewsletter="false"
                    :invalid="submitted && (!gdpr || !gdpr.gdprValue)"
                    :gdprTooltip="$t('gdpr.descriptions.privacy_policy_tooltip')"
                  />
                </div>

                <k-button
                  color="primary"
                  type="submit"
                  class="tw-mt-12"
                  :loading="cvSettingsStatus.isLoading"
                  :success="cvSettingsStatus.isSuccess"
                >
                  {{ $t('global.actions.save') }}
                </k-button>
              </form>
            </div>
          </section>

          <div class="tw-hidden lg:tw-block lg:tw-w-84 tw-flex-shrink-0">
            <!-- Empty -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sticky from 'vue-sticky'
import { mapGetters } from 'vuex'

export default {
  directives: {
    sticky: Sticky
  },
  data() {
    return {
      successfullyCopied: false,
      submitted: false,
      gdpr: null,
      languages: [
        {
          value: 'sk',
          text: 'Slovenský'
        },
        {
          value: 'en',
          text: 'English'
        }
      ],
      form: {
        showContact: true,
        showChart: true,
        audience: 'PRIVATE',
        token: null,
        language: 'sk'
      }
    }
  },
  computed: {
    ...mapGetters('AUTH', {
      userEmail: 'getAuthUserEmail'
    }),
    ...mapGetters('USER', {
      profile: 'getUserProfile',
      cvSettings: 'getCvSettings',
      hasUserGdprAccepted: 'getHasUserGdprAccepted'
    }),
    cvSettingsStatus() {
      return this.$store.getters['USER/getStatus']('updateCvSettingsStatus')
    },
    baseUrl() {
      return window.location.origin
    },
    cvUrl() {
      const query = this.cvSettings?.token ? `?token=${ this.cvSettings.token }` : ''

      return `${ this.baseUrl }/cv/${ this.profile?.id }${ query }`
    }
  },
  watch: {
    'cvSettings': {
      deep: true,
      immediate: true,
      handler(settings) {
        const { showChart, showContact, audience, token, language } = settings || {}

        this.form.audience = audience?.value || 'PRIVATE'
        this.form.showContact = showContact
        this.form.showChart = showChart
        this.form.token = token
        this.form.language = language
      }
    }
  },
  methods: {
    handleAudienceChange() {
      if (this.form.audience === 'PUBLIC') {
        this.form.showContact = true
      } else if (this.form.audience === 'PRIVATE') {
        this.form.showContact = false
      }
    },
    copyToClipboard() {
      const el = document.createElement('textarea')
      el.value = this.cvUrl
      el.setAttribute('readonly', '')
      // Make el invisible
      el.style.position = 'absolute'
      el.style.width = '0.063rem'
      el.style.height = '0.063rem'
      el.style.left = '-9999rem'
      el.style.clip = 'rect(0, 0, 0, 0);'
      el.style.overflow = 'hidden'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)

      // Show the user that the link has been successfully copied
      this.successfullyCopied = true
      setTimeout(() => {
        this.successfullyCopied = false
      }, 2500)
    },
    submit() {
      this.submitted = true

      if (!this.hasUserGdprAccepted && (!this.gdpr || !this.gdpr.gdprValue)) {
        return
      }

      const data = {
        ...this.cvSettings,
        url: null,
        showContactChangedByUser: null,
        showContact: this.form.showContact,
        showChart: this.form.showChart,
        audience: {
          value: this.form.audience
        },
        token: this.form.audience === 'TOKEN' ? this.form.token : null,
        language: this.form.language || 'sk'
      }

      this.$store.dispatch('USER/updateCvSettings', data)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

::v-deep .cv-url-input .v-text-field__prefix {
  color: #7f7f7f !important;
  font-size: 0.875rem !important;
  padding: 0 !important;
}
</style>
