<template>
  <div class="tw-relative tw-mx-auto tw-w-full tw-max-w-xl lg:tw-max-w-screen-xl">
    <ka-user-cv-linkedin-modal
      @response="fillLinkedinImportResponse"
      :value="linkedinDialog"
      @close="linkedinDialog = false"
    />

    <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-space-x-8">
      <div class="tw-hidden xl:tw-block xl:tw-w-44 tw-flex-shrink-0">
        <div v-sticky="{ zIndex: 1, stickyTop: 110, disabled: false }">
          <div class="tw-flex tw-flex-col tw-space-y-4 tw-text-right">
            <div v-for="link in links" :key="link.href">
              <a
                @click="$vuetify.goTo(link.id, { offset: link.offset })"
                class="tw-text-base tw-text-gray-800 tw-no-underline hover:tw-text-koderia tw-transition-colors tw-duration-200"
                :class="{ 'tw-text-koderia': activeSection === link.id }"
              >
                {{ $t(link.label) }}
              </a>

              <div v-if="link.subLinks" class="tw-flex tw-flex-col">
                <v-tooltip v-for="subLink in link.subLinks" :key="subLink.href" bottom>
                  <template v-slot:activator="{ on }">
                    <a
                      v-on="on"
                      @click="$vuetify.goTo(subLink.id, { offset: link.offset })"
                      class="tw-text-sm tw-text-gray-400 tw-no-underline hover:tw-text-koderia tw-transition-colors tw-duration-200 tw-block text-truncate"
                      :class="{ 'tw-text-koderia': activeSection === subLink.id }"
                    >
                      {{ subLink.label }}
                    </a>
                  </template>

                  <span>{{ subLink.label }}</span>
                </v-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tw-flex-grow">
        <div class="tw-flex tw-flex-col">
          <v-form ref="form">
            <section class="tw-bg-white tw-rounded-2xl tw-p-4 lg:tw-p-10">
              <ka-cv-form-section-profile v-model="form.general" />
            </section>

            <section class="tw-mt-10">
              <ka-cv-form-section
                id="employments"
                :title="$t('global.employment')"
                :sub-title="$t('profile.descriptions.employment')"
                :addTitle="form.employments.length ? this.$t('global.actions.add_more') : this.$t('global.actions.add')"
                @add="addSectionItem('employments')"
                :animated="!profileStatus.isLoading && form.employments.length > 1"
              >
                <template #icon>
                  <k-icon name="laptop" :size="20" />
                </template>

                <div
                  v-for="(employment, idx) in form.employments"
                  :key="employment.id"
                  :id="`id-${employment.id}`"
                >
                  <ka-cv-form-section-item-employment
                    v-model="form.employments[idx]"
                    :sub-title="`${$t('global.employment')} ${idx + 1}`"
                    @remove="removeSectionItem('employments', idx)"
                    @move-up="moveUpSectionItem('employments', idx)"
                    @move-down="moveDownSectionItem('employments', idx)"
                    @expertises-change="addEmploymentExpertises"
                    @import-from-previous="importTechFromPreviousEmployment(idx)"
                    :move-up-disabled="idx === 0"
                    :move-down-disabled="idx === form.employments.length - 1"
                    :bottom-line="idx < form.employments.length - 1"
                  />
                </div>
              </ka-cv-form-section>
            </section>

            <section class="tw-mt-10">
              <ka-cv-form-section
                id="expertises"
                :title="$t('profile.technologies')"
                simple
              >
                <template #icon>
                  <k-icon name="layer_group" :size="20" />
                </template>

                <template #divider>
                  <p class="tw-bg-blue-light tw-text-blue tw-text-sm tw-leading-relaxed tw-py-1 tw-px-3 tw-my-6 tw-rounded-md">
                    {{ $t('profile.descriptions.stack_notify') }}
                  </p>
                </template>

                <ka-cv-form-section-item-experience
                  v-model="form.expertises"
                  :selected-expertises-ids="selectedExpertisesIds"
                  @add="addSectionItem('expertises', $event)"
                />
              </ka-cv-form-section>
            </section>

            <section class="tw-mt-10">
              <ka-cv-form-section
                id="educations"
                :title="$t('profile.education')"
                :sub-title="$t('profile.descriptions.education')"
                :addTitle="$t(form.educations.length ? 'global.actions.add_more' : 'global.actions.add')"
                @add="addSectionItem('educations')"
                :animated="!profileStatus.isLoading && form.educations.length > 1"
              >
                <template #icon>
                  <k-icon name="user_graduate" :size="20" />
                </template>

                <div
                  v-for="(education, idx) in form.educations"
                  :key="education.id"
                  :id="`id-${education.id}`"
                >
                  <ka-cv-form-section-item-education
                    v-model="form.educations[idx]"
                    :sub-title="`${$t('profile.school')} ${idx + 1}`"
                    @remove="removeSectionItem('educations', idx)"
                    @move-up="moveUpSectionItem('educations', idx)"
                    @move-down="moveDownSectionItem('educations', idx)"
                    :move-up-disabled="idx === 0"
                    :move-down-disabled="idx === form.educations.length - 1"
                    :bottom-line="idx < form.educations.length - 1"
                  />
                </div>
              </ka-cv-form-section>
            </section>

            <section class="tw-mt-10">
              <ka-cv-form-section
                id="references"
                :title="$t('profile.references')"
                :sub-title="$t('profile.descriptions.references')"
                :addTitle="form.references.length ? $t('global.actions.add_more') : $t('global.actions.add')"
                @add="addSectionItem('references')"
                :animated="!profileStatus.isLoading && form.references.length > 1"
              >
                <template #icon>
                  <k-icon name="rocket" :size="16" />
                </template>

                <div
                  v-for="(reference, idx) in form.references"
                  :key="reference.id"
                  :id="`id-${reference.id}`"
                >
                  <ka-cv-form-section-item-reference
                    v-model="form.references[idx]"
                    :sub-title="`${$t('profile.reference')} ${idx + 1}`"
                    @remove="removeSectionItem('references', idx)"
                    @move-up="moveUpSectionItem('references', idx)"
                    @move-down="moveDownSectionItem('references', idx)"
                    :move-up-disabled="idx === 0"
                    :move-down-disabled="idx === form.references.length - 1"
                    :bottom-line="idx < form.references.length - 1"
                  />
                </div>
              </ka-cv-form-section>
            </section>

            <section class="tw-mt-10">
              <ka-cv-form-section
                id="certificates"
                :title="$t('profile.certificates')"
                :sub-title="$t('profile.descriptions.certificates')"
                :addTitle="form.certificates.length ? this.$t('global.actions.add_more') : this.$t('global.actions.add')"
                @add="addSectionItem('certificates')"
                :animated="!profileStatus.isLoading && form.certificates.length > 1"
              >
                <template #icon>
                  <k-icon name="certificate" :size="20" />
                </template>

                <div
                  v-for="(certificate, idx) in form.certificates"
                  :key="certificate.id"
                  :id="`id-${certificate.id}`"
                >
                  <ka-cv-form-section-item-certificate
                    v-model="form.certificates[idx]"
                    :sub-title="`${$t('profile.certificate')} ${idx + 1}`"
                    @remove="removeSectionItem('certificates', idx)"
                    @move-up="moveUpSectionItem('certificates', idx)"
                    @move-down="moveDownSectionItem('certificates', idx)"
                    :move-up-disabled="idx === 0"
                    :move-down-disabled="idx === form.certificates.length - 1"
                    :bottom-line="idx < form.certificates.length - 1"
                  />
                </div>
              </ka-cv-form-section>
            </section>

            <section class="tw-mt-10">
              <ka-cv-form-section
                id="languages"
                :title="$t('profile.languages')"
                :sub-title="$t('profile.descriptions.languages')"
                :addTitle="form.languages.length ? this.$t('global.actions.add_more') : this.$t('global.actions.add')"
                @add="addSectionItem('languages')"
                :animated="!profileStatus.isLoading && form.languages.length > 1"
              >
                <template #icon>
                  <k-icon name="language" :size="20" />
                </template>

                <div
                  v-for="(language, idx) in form.languages"
                  :key="language.id"
                  :id="`id-${language.id}`"
                >
                  <ka-cv-form-section-item-language
                    v-model="form.languages[idx]"
                    @remove="removeSectionItem('languages', idx)"
                    @move-up="moveUpSectionItem('languages', idx)"
                    @move-down="moveDownSectionItem('languages', idx)"
                    :move-up-disabled="idx === 0"
                    :move-down-disabled="idx === form.languages.length - 1"
                    :show-input-labels="idx === 0"
                    :no-border="true"
                    :dense="true"
                    :selected-languages-ids="selectedLanguagesIds"
                    class="tw-mb-6"
                  />
                </div>
              </ka-cv-form-section>
            </section>
          </v-form>
        </div>
      </div>

      <div
        v-sticky="{ zIndex: 10, disabled: !isMobile }"
        class="tw-w-full tw-mt-10 lg:tw-mt-0 lg:tw-w-84 tw-flex-shrink-0" :class="{ 'mobile-submit-panel': isMobile }"
      >
        <aside v-sticky="{ zIndex: 10, stickyTop: 110, disabled: isMobile }">
          <submit-panel
            :loading="profileStatus.isLoading"
            :is-generating-cv="pdfStatus.isLoading"
            :success="profileStatus.isSuccess"
            @submit="submit"
            @save-pdf="generatePdf"
            @linkedin-import="importFromLinkedin"
          />

          <!--                    TODO: Pridat neskor-->
          <!--                    <cv-job-offers :experience="form.experience" :profession="form.general.profession" />-->
        </aside>
      </div>
    </div>
  </div>
</template>

<script>
import Sticky from 'vue-sticky'

import SubmitPanel from './components/cv-submit-panel.vue'

import KaCvFormSection from './cv-sections/ka-cv-form-section'
import KaUserCvLinkedinModal from './ka-user-cv-linkedin-modal'
import KaCvFormSectionProfile from './cv-sections/ka-cv-form-section-profile'
import KaCvFormSectionItemLanguage from './cv-sections/ka-cv-form-section-item-language'
import KaCvFormSectionItemReference from './cv-sections/ka-cv-form-section-item-reference'
import KaCvFormSectionItemEducation from './cv-sections/ka-cv-form-section-item-education'
import KaCvFormSectionItemEmployment from './cv-sections/ka-cv-form-section-item-employment'
import KaCvFormSectionItemExperience from './cv-sections/ka-cv-form-section-item-experience'
import KaCvFormSectionItemCertificate from './cv-sections/ka-cv-form-section-item-certificate'
// import CvJobOffers from "@web/user/components/cv-job-offers.vue";
import { mapGetters } from 'vuex'
import { cloneDeep, debounce } from 'lodash'

const defaultSectionFormValues = {
  employments: {
    position: null,
    company: null,
    startYear: null,
    startMonth: null,
    endYear: null,
    endMonth: null,
    description: null,
    expertises: []
  },
  educations: {
    school: null,
    department: null,
    city: null,
    country: null,
    startYear: null,
    endYear: null,
    grade: null,
    description: null
  },
  references: {
    name: null,
    url: null,
    description: null
  },
  certificates: {
    name: null,
    description: null
  },
  languages: {
    language: null,
    level: null
  }
}

export default {
  directives: {
    sticky: Sticky
  },
  components: {
    // CvJobOffers,
    SubmitPanel,
    KaCvFormSection,
    KaUserCvLinkedinModal,
    KaCvFormSectionProfile,
    KaCvFormSectionItemLanguage,
    KaCvFormSectionItemReference,
    KaCvFormSectionItemEducation,
    KaCvFormSectionItemEmployment,
    KaCvFormSectionItemExperience,
    KaCvFormSectionItemCertificate
  },
  props: {
    preserveForm: {
      type: Boolean,
      default: false
    },
    pdfUrl: {
      type: String
    },
    isActive: {
      type: Boolean
    }
  },
  data() {
    return {
      prevScrollOffset: 0,
      activeSection: '',
      linkedInImportUsed: false,
      linkedinDialog: false,
      form: {
        general: {},
        employments: [],
        expertises: [],
        educations: [],
        references: [],
        certificates: [],
        languages: []
      },
      isMobile: this.$vuetify.breakpoint.xs,
      canAutoSave: false
    }
  },
  computed: {
    ...mapGetters('USER', {
      profile: 'getUserProfile'
    }),
    profileStatus() {
      return this.$store.getters['USER/getStatus']('updateProfileStatus')
    },
    pdfStatus() {
      return this.$store.getters['USER/getStatus']('generatePdfStatus')
    },
    links() {
      return [
        {
          id: '#basic-info',
          offset: 145,
          label: 'profile.basic_information'
        },
        {
          id: '#employments',
          offset: 100,
          label: 'global.employment',
          subLinks: this.form.employments.map(item => ({
            label: this.createLinkLabel(item.position, item.company),
            id: `#id-${ item.id }`
          }))
        },
        {
          id: '#expertises',
          offset: 100,
          label: 'profile.technologies'
        },
        {
          id: '#educations',
          offset: 100,
          label: 'profile.education',
          subLinks: this.form.educations.map(item => ({
            label: this.createLinkLabel(item.school, item.department),
            id: `#id-${ item.id }`
          }))
        },
        {
          id: '#references',
          offset: 100,
          label: 'profile.references',
          subLinks: this.form.references.map(item => ({
            label: item.name,
            id: `#id-${ item.id }`
          }))
        },
        {
          id: '#certificates',
          offset: 100,
          label: 'profile.certificates',
          subLinks: this.form.certificates.map(item => ({
            label: item.name,
            id: `#id-${ item.id }`
          }))
        },
        {
          id: '#languages',
          offset: 100,
          label: 'profile.languages'
        }
      ]
    },
    selectedExpertisesIds() {
      return this.form.expertises.filter(({ expertiseId }) => expertiseId).map(({ expertiseId }) => expertiseId)
    },
    selectedLanguagesIds() {
      return this.form.languages.filter(({ language }) => language?.id).map(({ language }) => language.id)
    },
    sectionsHashes() {
      const hashes = []

      this.links.forEach(link => {
        hashes.push({
          hash: link.id,
          id: link.id,
          offset: link.offset
        })

        if (!link.subLinks) {
          return
        }

        link.subLinks.forEach(subLink => {
          hashes.push({
            hash: `#${ subLink.label }`,
            id: subLink.id,
            offset: link.offset
          })
        })
      })

      return hashes
    }
  },
  created() {
    this.$store.dispatch('ENUMS/fetchLanguages')
    this.fillFormData()

    this.$watch('form', debounce(() => {
      if (!this.$refs.form) {
        return
      }

      if (this.profileStatus.isLoading) {
        return
      }

      // this is just trick for first time data load
      if (!this.canAutoSave) {
        this.canAutoSave = true

        return
      }

      this.submit()
    }, 5000), { deep: true, immediate: false })
  },
  mounted() {
    const { hash } = this.$route

    if (!hash) {
      this.setActiveHash()
    } else {
      setTimeout(() => {
        const elementHash = this.sectionsHashes.find(el => el.hash === decodeURIComponent(hash))

        if (elementHash) {
          this.$vuetify.goTo(elementHash.id, { offset: elementHash.offset })
        }
      }, 250)
    }

    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },
  watch: {
    'profile': {
      immediate: true,
      deep: true,
      handler() {
        this.fillFormData()
        this.canAutoSave = false
      }
    },
    links: {
      deep: true,
      handler() {
        this.onLinksUpdate()
      }
    }
  },
  methods: {
    createLinkLabel(primaryText, secondaryText) {
      if (!secondaryText) {
        return primaryText
      }

      return `${ primaryText } - ${ secondaryText }`
    },
    onScroll: debounce(function () {
      this.setActiveHash()
    }, 300),
    onLinksUpdate: debounce(function () {
      this.setActiveHash()
    }, 750),
    setActiveHash() {
      if (!this.isActive) {
        return
      }

      const elements = []

      this.sectionsHashes.forEach(elementHash => {
        elements.push(document.getElementById(elementHash.id.slice(1)))
      })

      if (elements.length === 0) {
        return
      }

      const scrollTop = Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
      )

      const direction = this.prevScrollOffset < window.pageYOffset ? 'down' : 'up'
      this.prevScrollOffset = window.pageYOffset

      for (let i = 0; i < elements.length; i++) {
        const element = elements[i]
        const nextElement = elements[i + 1]

        const nextElementOffset = direction === 'down' ? 100 : 150

        const isActive = i === 0 && scrollTop === 0
          || (scrollTop >= (element.offsetTop + 100)
            && (!nextElement || scrollTop < (nextElement.offsetTop + nextElementOffset)))

        if (isActive && element.id !== this.activeSection) {
          this.activeSection = `#${ element.id }`

          const elementHash = this.sectionsHashes.find(item => item.id === this.activeSection)

          if (!elementHash) {
            return
          }

          const { name, params } = this.$route

          this.$router.replace({ name, params, hash: elementHash.hash })
        }
      }
    },
    addEmploymentExpertises(employmentExpertises) {
      const copy = cloneDeep(this.form.expertises);

      (employmentExpertises || []).forEach(expertise => {
        if ((this.selectedExpertisesIds).indexOf(expertise.expertiseId) === -1) {
          if (copy[copy.length - 1].expertiseId === null) {
            copy[copy.length - 1] = expertise
          } else {
            copy.push(expertise)
          }
        }
      })

      this.form.expertises = cloneDeep(copy)
    },
    importTechFromPreviousEmployment(idx) {
      const newExpertises = []

      this.form.employments[idx - 1].expertises.forEach(({ id, ...expertise }) => {
        const existingTech = this.form.employments[idx].expertises.find(({ expertiseId }) => expertiseId === expertise.expertiseId)

        if (!existingTech) {
          newExpertises.push(expertise)
        }
      })

      if (!newExpertises.length) {
        return
      }

      newExpertises.forEach(expertise => {
        this.form.employments[idx].expertises.push(expertise)
      })
    },
    generatePdf() {
      this.$store.dispatch('USER/generatePdf')
    },
    importFromLinkedin() {
      this.linkedinDialog = true
    },
    fillFormData() {
      if (this.preserveForm === true || !this.profile) {
        return
      }

      const form = {}

      const {
        expertises,
        employments,
        educations,
        references,
        certificates,
        languages,
        ...general
      } = this.profile

      form['general'] = general
      form['expertises'] = this.checkSectionSeq(expertises)
      form['employments'] = this.checkSectionSeq(employments)
      form['educations'] = this.checkSectionSeq(educations)
      form['certificates'] = this.checkSectionSeq(certificates)
      form['references'] = this.checkSectionSeq(references)
      form['languages'] = this.checkSectionSeq(languages)

      this.form = cloneDeep(form)
    },
    checkSectionSeq(sectionArray) {
      const sortedSectionArray = sectionArray.toSorted((a, b) => (a.seq ?? 10000) - (b.seq ?? 10000))

      return sortedSectionArray.map((item, idx) => {
        if (item.seq === null) {
          return {
            ...item,
            seq: idx
          }
        }

        return item
      })
    },
    scrollToFirstFieldError() {
      const el = document.querySelector('.error--text')

      if (el) {
        window.scrollTo({
          top: el.getBoundingClientRect().top + window.pageYOffset - 150,
          behavior: 'smooth'
        })
      }
    },
    submit() {
      if (!this.$refs.form.validate()) {
        setTimeout(() => this.scrollToFirstFieldError(), 100)

        return
      }

      const expertises = this.form.expertises.length > 0
        ? this.clearArrayBeforeSubmit(this.form.expertises).slice(0, -1)
        : []
      const employments = this.clearArrayBeforeSubmit(this.form.employments)
        .map(employment => {
          employment.expertises = employment.expertises.map(({ expertiseId }) => ({ expertiseId }))

          return employment
        })
      const educations = this.clearArrayBeforeSubmit(this.form.educations)
      const references = this.clearArrayBeforeSubmit(this.form.references)
      const certificates = this.clearArrayBeforeSubmit(this.form.certificates)
      const languages = this.clearArrayBeforeSubmit(this.form.languages)

      const detail = {
        ...this.form.general,
        gdprAgreementAccepted: true,
        expertises,
        employments,
        educations,
        references,
        certificates,
        languages
      }

      this.$store.dispatch('USER/updateProfile', detail)
    },
    clearArrayBeforeSubmit(arr) {
      return arr.map(({ id, ...rest }) => ({ ...rest, id: typeof id === 'number' ? null : id }))
    },
    addSectionItem(sectionName, data = null) {
      const formData = data === null ? cloneDeep(defaultSectionFormValues[sectionName]) : data

      this.form[sectionName].push({
        ...formData,
        seq: this.form[sectionName].length,
        id: this.generateRandomId()
      })
    },
    removeSectionItem(sectionName, idx) {
      this.form[sectionName].splice(idx, 1)
    },
    moveUpSectionItem(sectionName, idx) {
      const arr = this.form[sectionName]
      this.form[sectionName] = this.arrayMove(arr, idx, idx > 1 ? idx - 1 : 0)
    },
    moveDownSectionItem(sectionName, idx) {
      const arr = this.form[sectionName]
      this.form[sectionName] = this.arrayMove(arr, idx, idx < arr.length - 1 ? idx + 1 : arr.length - 1)
    },
    arrayMove(sourceArr, old_index, new_index) {
      const arr = cloneDeep(sourceArr)

      if (new_index >= arr.length) {
        let k = new_index - arr.length + 1

        while (k--) {
          arr.push(undefined)
        }
      }

      arr.splice(new_index, 0, arr.splice(old_index, 1)[0])

      return cloneDeep(arr.map((item, idx) => ({
        ...item,
        seq: idx
      })))
    },
    generateRandomId() {
      return Math.random() * 1_000_000
    },
    fillLinkedinImportResponse(response) {
      this.linkedInImportUsed = true;

      (response || []).forEach(section => {
        // try {
        //     // Experience
        //     if (section.name === "skills") {
        //         (section.content || []).forEach(item => {
        //             this.addExperience(item["Name"]);
        //         });
        //     }
        // } catch (e) {
        //     console.error(e);
        // }

        try {
          // Certificate
          if (section.name === 'courses') {
            (section.content || []).forEach(item => {
              this.addSectionItem('certificate', {
                name: item['Name']
              })
            })
          }
        } catch (e) {
          console.error(e)
        }

        try {
          // Education
          if (section.name === 'education') {
            (section.content || []).forEach(item => {
              this.addSectionItem('education', {
                grade: item['Degree Name'],
                school: item['School Name'],
                city: '',
                country: '',
                from: +item['Start Date'],
                to: +item['End Date'] || 'dnes',
                description: item['Notes']
              })
            })
          }
        } catch (e) {
          console.error(e)
        }

        try {
          // Employment
          if (section.name === 'positions') {
            const year = val => {
              const y = (val || '').split(' ')[1]
              return +y
            }

            const month = val => {
              const mMap = {
                Jan: 'január',
                Feb: 'február',
                Mar: 'marec',
                Apr: 'apríl',
                May: 'máj',
                Jun: 'jún',
                Jul: 'júl',
                Aug: 'august',
                Sep: 'september',
                Oct: 'október',
                Nov: 'november',
                Dec: 'december'
              }
              const m = (val || '').split(' ')[0]
              return mMap[m]
            };

            (section.content || []).forEach(item => {
              const employment = {
                position: item['Title'],
                company: item['Company Name'],
                toMonth: month(item['Finished On']) || 'dnes',
                toYear: year(item['Finished On']),
                fromMonth: month(item['Started On']),
                fromYear: year(item['Started On']),
                description: item['Description']
              }
              this.addSectionItem('employment', employment)
            })
          }
        } catch (e) {
          console.error(e)
        }

        try {
          // Language
          if (section.name === 'languages') {
            const levelsMap = {
              'native or bilingual proficiency': 'native-speaker',
              'full professional proficiency': 'expert',
              'professional working proficiency': 'advanced',
              'limited working proficiency': 'intermediate',
              'elementary proficiency': 'beginner'
            };

            (section.content || []).forEach(item => {
              this.addSectionItem('language', {
                language: item['Name'],
                level: levelsMap[String(item['Proficiency']).toLowerCase()] || 'beginner'
              })
            })
          }
        } catch (e) {
          console.error(e)
        }

        try {
          // Profile
          if (section.name === 'profile') {
            this.form.general.firstName = this.form.general.firstName || section.content[0]['First Name']
            this.form.general.lastName = this.form.general.lastName || section.content[0]['Last Name']
            this.form.general.bio = this.form.general.bio || section.content[0]['Summary']
          }
        } catch (e) {
          console.error(e)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

.mt-13 {
  margin-top: 3.25rem;
}

.edit-in-profile-reminder {
  margin-top: -1rem;
}

.animated-list-move {
  transition: transform 0.5s;
}

.mobile-submit-panel {
  bottom: 0;
  left: 0;
  padding-block: 1rem;
  background: rgb(243, 244, 246);
}
</style>
