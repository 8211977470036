<template>
  <ka-cv-form-section-item-base v-bind="$attrs" v-on="$listeners">
    <div class="tw-grid tw-gap-4">
      <div class="tw-flex tw-flex-col-reverse sm:tw-flex-row tw-space-y-reverse tw-space-y-4 sm:tw-space-y-0 sm:tw-space-x-4">
        <k-input
          v-model.trim="form.position"
          :title="$t('global.inputs.position')"
          required
          :rules="[v => !!v]"
          class="tw-w-full"
        />
        <ka-cv-form-section-item-actions
          @move-up="$emit('move-up')"
          @move-down="$emit('move-down')"
          @remove="$emit('remove')"
          :moveUpDisabled="moveUpDisabled"
          :moveDownDisabled="moveDownDisabled"
          class="tw-items-end"
        />
      </div>

      <k-input
        v-model.trim="form.company"
        :title="$t('global.inputs.company')"
      />

      <div class="tw-flex tw-flex-col sm:tw-flex-row tw-space-y-4 sm:tw-space-y-0 sm:tw-space-x-14">
        <div class="tw-w-full has-divider-after">
          <label class="tw-font-bold tw-pb-2 tw-inline-block tw-text-gray-800 tw-text-sm">
            {{ $t('global.start') }}
          </label>
          <div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-4">
            <k-select
              :items="months"
              v-model.trim="form.startMonth"
              :placeholder="$t('global.time.month')"
            />
            <k-select
              :items="years"
              v-model.trim="form.startYear"
              :placeholder="$t('global.time.year')"
            />
          </div>
        </div>

        <div class="tw-w-full">
          <label class="tw-font-bold tw-pb-2 tw-inline-block tw-text-gray-800 tw-text-sm">{{ $t('global.end') }}</label>
          <div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-4">
            <k-select
              :items="[$t('global.time.today'), ...months]"
              v-model.trim="form.endMonth"
              :placeholder="$t('global.time.month')"
            />
            <k-select
              :items="years"
              v-model.trim="form.endYear"
              :placeholder="$t('global.time.year')"
              :style="`opacity: ${hideToYear ? 0 : 1}; visibility: ${hideToYear ? 'hidden' : 'visible'}`"
            />
          </div>
        </div>
      </div>


      <k-autocomplete-technologies
        :label="$t('profile.inputs.technologies')"
        :sub-title="$t('profile.descriptions.technology_input')"
        :value="form.expertises"
        @input="handleExpertisesChange"
        :showLimit="3"
      >
        <template v-if="!moveUpDisabled" v-slot:prepend-item>
          <div class="tw-flex tw-justify-end tw-mb-4">
            <k-btn
              @click="$emit('import-from-previous')"
              size="sm"
              color="primary"
            >
              {{ $t('profile.actions.pick_previous_tech') }}
            </k-btn>
          </div>
        </template>
      </k-autocomplete-technologies>

      <k-textarea
        :title="$t('profile.inputs.description')"
        :sub-title="$t('profile.descriptions.employment_description_input')"
        v-model.trim="form.description"
        class="tw-mt-2"
      />
    </div>
  </ka-cv-form-section-item-base>
</template>

<script>
import KaCvFormSectionItemBase from './ka-cv-form-section-item-base'
import KaCvFormSectionItemActions from './ka-cv-form-section-item-actions'
import { cloneDeep, isEqual } from 'lodash'

export default {
  components: {
    KaCvFormSectionItemBase,
    KaCvFormSectionItemActions
  },
  props: {
    value: {
      type: Object
    },
    moveUpDisabled: {
      type: Boolean,
      default: false
    },
    moveDownDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hideToYear: false,
      form: cloneDeep(this.value)
    }
  },
  computed: {
    months() {
      return [
        this.$t('global.time.january'),
        this.$t('global.time.february'),
        this.$t('global.time.march'),
        this.$t('global.time.april'),
        this.$t('global.time.may'),
        this.$t('global.time.june'),
        this.$t('global.time.july'),
        this.$t('global.time.august'),
        this.$t('global.time.september'),
        this.$t('global.time.october'),
        this.$t('global.time.november'),
        this.$t('global.time.december')
      ]
    },
    years() {
      const now = new Date().getUTCFullYear()
      return Array(now - (now - 100))
        .fill('')
        .map((v, idx) => now - idx)
    },
    appLocale() {
      return this.$store.getters.appLocale
    }
  },
  methods: {
    handleExpertisesChange(expertises) {
      this.form.expertises = expertises

      this.$emit('expertises-change', expertises)
    }
  },
  watch: {
    'form': {
      deep: true,
      handler() {
        this.$emit('input', { ...this.form })
      }
    },
    'form.endMonth': {
      immediate: true,
      handler(v) {
        if (v === 'Dnes' || v === 'Today') {
          this.hideToYear = true
          this.form.endYear = null
        } else {
          this.hideToYear = false
        }

        if (this.appLocale === 'sk' && v === 'Today') {
          this.form.endMonth = 'Dnes'
        } else if (this.appLocale === 'en' && v === 'Dnes') {
          this.form.endMonth = 'Today'
        }
      }
    },
    // need to be like this because of copy expertises, computed doesnt work
    'value.expertises': {
      deep: true,
      handler() {
        if (!isEqual(this.value.expertises, this.form.expertises)) {
          this.form.expertises = cloneDeep(this.value.expertises)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

.has-divider-after {
  position: relative;
}

.has-divider-after::after {
  content: "";
  position: absolute;
  width: 1.5rem;
  height: 0.125rem;
  background-color: #edeff2;
  right: -2.5rem;
  bottom: 1.375rem;

  display: none;
  @include sm {
    display: block;
  }
}
</style>
