<template>
    <v-dialog v-model="dialog" persistent max-width="1050">
        <v-card>
            <div class="wrapper">
                <v-btn icon @click="close" class="close-button" :disabled="uploading">
                    <k-icon name="close" size="40" color="black" />
                </v-btn>

                <h1 class="tw-heading-2">{{ $t('profile.subtitles.linkedin_import_data') }}</h1>
                <v-col class="step-1">
                    <v-row align="center">
                        <span class="step-number">1</span>
                        <p class="step-info">
                            {{ $t('profile.descriptions.linkedin_open_personal_data') }} –
                            <strong>{{ $t('profile.descriptions.linkedin_use_your_data') }}</strong>
                            &nbsp;
                            <a
                                href="https://www.linkedin.com/psettings/member-data#how-LinkedIn-uses-your-data"
                                target="_blank"
                                rel="noopener noreferrer"
                                class="kod-link-primary hidden-desktop"
                            >
                                {{ $t('global.actions.open') }}
                            </a>
                        </p>

                        <k-button
                            href="https://www.linkedin.com/psettings/member-data#how-LinkedIn-uses-your-data"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="ml-6 hidden-mobile"
                        >
                            {{ $t('global.actions.open') }}
                        </k-button>
                    </v-row>
                </v-col>

                <v-col class="step-2">
                    <v-row align="center">
                        <span class="step-number">2</span>
                        <p class="step-info" v-html="$t('profile.descriptions.linkedin_download_large_data')" />
                    </v-row>
                </v-col>

                <v-col class="step-3">
                    <v-row align="center">
                        <span class="step-number">3</span>
                        <p class="step-info" v-html="$t('profile.descriptions.linkedin_request_archive')" />
                    </v-row>
                </v-col>

                <v-col class="step-4">
                    <v-row align="center">
                        <div class="step-4__content">
                            <span class="step-number">4</span>
                            <p class="step-info">
                                {{ $t('profile.descriptions.linkedin_unzip_file') }} <strong>Languages</strong>, <strong>Courses</strong>,
                                <strong>Positions</strong>, <strong>Profile</strong> {{ $t('global.and') }} <strong>Education</strong>.
                                <span v-html="$t('profile.descriptions.import_click')" /> {{ $t('profile.descriptions.linkedin_provide_file') }}
                            </p>
                        </div>
                    </v-row>
                </v-col>

                <div class="step-5">
                    <div class="mt-4">
                        <k-button appearance="secondary-dark" @click="handleUploadFile()" class="k-button--icon-left" :disabled="uploading">
                            <k-icon name="attach" />
                            {{ $t('global.actions.upload_files') }}
                        </k-button>
                    </div>

                    <div class="files" v-show="files && files.length">
                        <div v-for="(file, idx) in files" :key="file.name" class="file-item">
                            {{ file.name }}
                            <v-btn small icon @click="removeFile(idx)" :disabled="uploading" class="file-item-button" color="black">
                                <k-icon name="close" size="12" />
                            </v-btn>
                        </div>
                    </div>

                    <div class="mt-8">
                        <k-button @click="upload" :loading="uploading" id="ga_linkedin-import">
                            {{ $t('global.actions.IMPORT') }}
                        </k-button>
                    </div>
                </div>

                <img src="./assets/linkedin-export.png" alt="LinkedIn Export" class="linkedin-export" />
                <img src="./assets/arrow-right.svg" alt="Arrow Right" class="arrow-right arrow-right--step-1" />
                <img src="./assets/arrow-right.svg" alt="Arrow Right" class="arrow-right arrow-right--step-2" />
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import firebase from "firebase";
import "firebase/functions";
import "firebase/storage";

export default {
    props: ["value"],
    data() {
        return {
            dialog: false,
            uploading: false,
            files: []
        };
    },
    computed: {
        user() {
            return this.$store.state.AUTH.user;
        }
    },
    watch: {
        value(val) {
            this.dialog = !!val;
        }
    },
    methods: {
        removeFile(idx) {
            var newFileList = Array.from(this.files);
            newFileList.splice(idx, 1);
            this.files = newFileList;
        },
        handleUploadFile() {
            var input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("name", "files");
            input.setAttribute("accept", ".csv,.zip");
            input.setAttribute("multiple", "multiple");
            input.onchange = e => {
                this.files = e.target.files;
            };
            input.dispatchEvent(new MouseEvent("click"));
        },
        async upload() {
            try {
                if (!(this.files && this.files.length > 0)) {
                    return;
                }

                this.uploading = true;
                const { uid } = this.user || {};
                if (!uid) return;

                const p = [];
                (this.files || []).forEach(file => {
                    const uploadFilesRef = firebase.storage().ref(`/tmp/linkedin/${uid}/${file.name}`);
                    p.push(uploadFilesRef.put(file));
                });

                await Promise.all(p);

                const importLinkedinData = firebase.functions().httpsCallable("importLinkedinData");
                const response = await importLinkedinData();
                const { status, data } = response.data || {};

                if (status === "ok") {
                    this.$emit("response", data);
                    this.close();
                } else {
                    throw response;
                }
            } catch (error) {
                console.error(error);
            }
            this.uploading = false;
        },
        close() {
            this.dialog = false;
            this.uploading = false;
            this.files = [];
            this.$emit("close");
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

.wrapper {
    padding: 4rem 2.5rem;
    position: relative;
}

.step-number {
    width: 2.5rem !important;
    height: 2.5rem !important;
    color: white;
    background-color: $kod-black;
    border-radius: 9999px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;

    @include -lg {
        display: flex;
    }
}

.step-info {
    margin-left: 1rem;

    @include -lg {
        margin-top: 0.5rem;
        display: block;
        width: 100%;
        margin-left: 0;
    }
}

.step-1 {
    @include lg {
        margin-top: 2.25rem;
    }
}

.step-2 {
    @include lg {
        margin-top: 1.625rem;
    }
}

.step-3 {
    @include lg {
        margin-top: 14.75rem;
    }
}

.step-4 {
    @include lg {
        margin-top: 12.75rem;
    }
}

.step-4__content {
    display: grid;
    @include lg {
        grid-auto-flow: column;
        .step-info {
            margin-top: 0.5em;
        }
    }
}

.step-5 {
    @include lg {
        margin-left: 3.5rem;
    }
}

.arrow-right {
    @include -lg {
        display: none;
    }
    position: absolute;
    height: 9.375rem;
    width: auto;
    top: 20.5rem;
    right: 33rem;
    -webkit-user-drag: none;
    pointer-events: none;
    user-select: none;
}

.arrow-right--step-1 {
}

.arrow-right--step-2 {
    top: 36.5rem;
}

.linkedin-export {
    @include -lg {
        display: none;
    }
    position: absolute;
    height: 28.3125rem;
    width: auto;
    top: 20rem;
    right: 2.5rem;
    -webkit-user-drag: none;
    pointer-events: none;
    user-select: none;
}

.files {
    display: grid;
    grid-auto-flow: row;
    gap: 0.5rem;
    width: 100%;
    font-size: 0.75rem;
    line-height: 1.125rem;
    margin-top: 1.5rem;
}

.file-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $kod-hr;
    padding: 0.5rem;
    width: 100%;
}

.file-item-button {
    position: absolute;
    right: 0.5rem;
    color: $kod-black;
}

.close-button {
    color: $kod-black;
    position: absolute;
    top: 2rem;
    right: 2rem;
}
</style>
