<template>
  <ka-cv-form-section-item-base v-bind="$attrs" v-on="$listeners">
    <div class="tw-grid tw-gap-4">
      <div class="tw-flex tw-flex-col-reverse sm:tw-flex-row tw-space-y-reverse tw-space-y-4 sm:tw-space-y-0 sm:tw-space-x-4">
        <div class="tw-flex tw-flex-col sm:tw-flex-row tw-space-y-4 sm:tw-space-y-0 sm:tw-space-x-4 tw-w-full">
          <k-input
            v-model.trim="form.school"
            :title="$t('profile.inputs.school_university')"
            class="tw-w-full"
            required
            :rules="[v => !!v]"
          />
          <k-input
            v-model.trim="form.department"
            :title="$t('profile.inputs.faculty')"
            class="tw-w-full"
          />
        </div>

        <ka-cv-form-section-item-actions
          @move-up="$emit('move-up')"
          @move-down="$emit('move-down')"
          @remove="$emit('remove')"
          :moveUpDisabled="moveUpDisabled"
          :moveDownDisabled="moveDownDisabled"
          class="tw-self-end"
        />
      </div>

      <div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-4">
        <div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-4">
          <k-input v-model.trim="form.city" :title="$t('global.inputs.city')" />
          <k-input v-model.trim="form.country" :title="$t('profile.inputs.country')" />
        </div>

        <div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-4 sm:tw-gap-6">
          <k-select
            :items="years()"
            v-model="form.startYear"
            :title="$t('global.start')"
            class="has-divider-after"
          />
          <k-select
            :items="[$t('global.time.today'), ...years(true)]"
            v-model="form.endYear"
            :title="$t('global.end')"
          />
        </div>
      </div>

      <k-input
        v-model.trim="form.grade"
        :title="$t('profile.inputs.education_diploma')"
        :placeholder="$t('profile.descriptions.education_diploma_placeholder')"
        class="tw-w-full"
      />

      <k-textarea
        v-model.trim="form.description"
        :title="$t('profile.inputs.description')"
        :sub-title="$t('profile.descriptions.education_description_input')"
      />
    </div>
  </ka-cv-form-section-item-base>
</template>

<script>
import KaCvFormSectionItemBase from './ka-cv-form-section-item-base'
import KaCvFormSectionItemActions from './ka-cv-form-section-item-actions'

export default {
  components: {
    KaCvFormSectionItemBase,
    KaCvFormSectionItemActions
  },
  props: {
    value: {
      type: Object
    },
    moveUpDisabled: {
      type: Boolean,
      default: false
    },
    moveDownDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: { ...this.value }
    }
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.$emit('input', { ...this.form })
      }
    }
  },
  methods: {
    years(mapToString = false, backTo = 100) {
      const now = new Date().getUTCFullYear()
      let years = Array(now - (now - backTo))
        .fill('')
        .map((v, idx) => now - idx)

      if (mapToString) {
        years = years.map(String)
      }

      return years
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

.has-divider-after {
  position: relative;
}

.has-divider-after::after {
  content: "";
  position: absolute;
  width: 1.5rem;
  height: 0.125rem;
  background-color: #edeff2;
  right: -1.5rem;
  bottom: 1.375rem;

  display: none;
  @include sm {
    display: block;
  }
}
</style>
