<template>
  <ka-cv-form-section-item-base v-bind="$attrs" v-on="$listeners">
    <div class="tw-grid tw-gap-4">
      <div class="tw-flex tw-flex-col-reverse sm:tw-flex-row tw-space-y-reverse tw-space-y-4 sm:tw-space-y-0 sm:tw-space-x-4">
        <k-input
          v-model.trim="form.name"
          :title="$t('global.inputs.name')"
          class="tw-w-full"
          required
          :rules="[v => !!v]"
        />
        <ka-cv-form-section-item-actions
          @move-up="$emit('move-up')"
          @move-down="$emit('move-down')"
          @remove="$emit('remove')"
          :moveUpDisabled="moveUpDisabled"
          :moveDownDisabled="moveDownDisabled"
          class="tw-self-end"
        />
      </div>
      <k-textarea
        v-model.trim="form.description"
        :title="$t('profile.inputs.description')"
        :sub-title="$t('profile.descriptions.certificates_description_input')"
      />
    </div>
  </ka-cv-form-section-item-base>
</template>

<script>
import KaCvFormSectionItemBase from './ka-cv-form-section-item-base'
import KaCvFormSectionItemActions from './ka-cv-form-section-item-actions'

export default {
  components: {
    KaCvFormSectionItemBase,
    KaCvFormSectionItemActions
  },
  props: {
    value: {
      type: Object
    },
    moveUpDisabled: {
      type: Boolean,
      default: false
    },
    moveDownDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: { ...this.value }
    }
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.$emit('input', { ...this.form })
      }
    }
  }
}
</script>
