import { render, staticRenderFns } from "./ka-cv-form-section-item-reference.vue?vue&type=template&id=3fbe7893&"
import script from "./ka-cv-form-section-item-reference.vue?vue&type=script&lang=js&"
export * from "./ka-cv-form-section-item-reference.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KInput from '@web/components/core/k-input'
import KTextarea from '@web/components/core/k-textarea'
installComponents(component, {KInput,KTextarea})
