<template>
  <ka-cv-form-section-item-base v-bind="$attrs" v-on="$listeners">
    <div class="tw-flex tw-flex-col-reverse sm:tw-flex-row tw-space-y-reverse tw-space-y-4 sm:tw-space-y-0 sm:tw-space-x-4">
      <div class="tw-w-full tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-4">
        <k-combobox
          v-model="form.language"
          item-text="name"
          item-value="id"
          :title="showInputLabels ? $t('profile.inputs.language') : undefined"
          :items="filteredLanguages"
          required
          :rules="[v => !!v]"
        />

        <k-combobox
          v-model="form.level"
          item-text="displayString"
          :items="languageLevels"
          :title="showInputLabels ? $t('profile.inputs.level') : undefined"
          :rules="[v => !!v]"
          required
        />
      </div>
      <ka-cv-form-section-item-actions
        @move-up="$emit('move-up')"
        @move-down="$emit('move-down')"
        @remove="$emit('remove')"
        :moveUpDisabled="moveUpDisabled"
        :moveDownDisabled="moveDownDisabled"
        class="tw-self-end"
      />
    </div>
  </ka-cv-form-section-item-base>
</template>

<script>
import KaCvFormSectionItemBase from './ka-cv-form-section-item-base'
import KaCvFormSectionItemActions from './ka-cv-form-section-item-actions'
import { mapGetters } from 'vuex'
import KCombobox from '@web/components/core/k-combobox.vue'

export default {
  components: {
    KCombobox,
    KaCvFormSectionItemBase,
    KaCvFormSectionItemActions
  },
  props: {
    value: {
      type: Object
    },
    moveUpDisabled: {
      type: Boolean,
      default: false
    },
    moveDownDisabled: {
      type: Boolean,
      default: false
    },
    showInputLabels: {
      type: Boolean,
      default: true
    },
    selectedLanguagesIds: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      form: { ...this.value }
    }
  },
  computed: {
    ...mapGetters({
      languages: 'ENUMS/getLanguages',
      languageLevels: 'ENUMS/getLanguageLevels'
    }),
    filteredLanguages() {
      return this.languages.filter(language => !this.selectedLanguagesIds.includes(language.id))
    }
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.$emit(
          'input',
          {
            id: this.form.id,
            language: {
              id: this.form.language?.id,
              name: this.form.language?.name
            },
            level: {
              value: this.form.level?.value,
              displayString: this.form.level?.displayString
            }
          }
        )
      }
    }
  }
}
</script>
