import { render, staticRenderFns } from "./ka-user-cv-tab-settings.vue?vue&type=template&id=0f532206&scoped=true&"
import script from "./ka-user-cv-tab-settings.vue?vue&type=script&lang=js&"
export * from "./ka-user-cv-tab-settings.vue?vue&type=script&lang=js&"
import style0 from "./ka-user-cv-tab-settings.vue?vue&type=style&index=0&id=0f532206&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f532206",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KButton from '@web/components/core/k-button'
import KGdpr from '@web/components/core/k-gdpr'
import KInput from '@web/components/core/k-input'
import KRadio from '@web/components/core/k-radio'
import KSelect from '@web/components/core/k-select'
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
installComponents(component, {KButton,KGdpr,KInput,KRadio,KSelect,VRadioGroup})
